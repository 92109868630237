import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bag } from '../domain/Bag';
import HttpInterceptor from '../services/HttpInterceptor';
import BagCard from './BagCard';
import { getALLBags, getMyBags, registerForPushNotification } from '../services/bagservice';
import MyBagCard from './MyBagCard';
import ConfirmationMessage from './ConfirmationMessage';
import { CREATE_BAG } from './NavigationUrl';
import { onMessageListener, requestFirebaseNotificationPermission } from './utils/firebase';
import { useAuth } from '../domain/AuthContext';

const MyBagList: React.FC = () => {
  const [bags, setBags] = useState<Bag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated, user, logout } = useAuth();
    const [notification, setNotification] = useState<{ title: string; body: string } | null>(null);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const token = await requestFirebaseNotificationPermission();
        console.log('Firebase token:', token);
        console.log("username is : " , user?.username);
        console.log("is authenticatd ? " , isAuthenticated);
        //send token and username to backend using post .
        if (token && user?.username)
           await registerForPushNotification(token,user?.username );
      } catch (err) {
        console.error('Notification permission denied:', err);
      }
    };

    requestPermission();

    onMessageListener()
      .then((payload: any) => {
        console.log('Received foreground message:', payload);
        setNotification({
          title:  payload.notification?.title || 'Notification',
          body: payload.notification?.body,
        });
      })
      .catch((err) => console.error('Failed to receive message:', err));

     // checkNotificationPermission();
  }, [user]);
 

  useEffect(() => {
    
    getMyBags().then( data => {
      console.log(data);
      setBags(data);
      setLoading(false);
    }).catch( error => {
      setError('Failed to fetch users');

      console.log(error);
    })
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if(bags.length == 0) return <ConfirmationMessage 
              message='You have not offered any bag space yet.'  
              frontButtonLabel="Offer bag space"  
              showFrontButton={true}
              frontRoute={CREATE_BAG}/>
  return (


    <div className="property-list">
   <h2 className="form-title">Your bags </h2>      
       {
       
       bags.map((bag) => (
        <MyBagCard key={bag.id} bag={bag} />
      )
    
    )
      }
    </div>
  );
};

export default MyBagList;
