import React, { useEffect, useState } from 'react';
import '../css/bagdetail.css';
import CarrierDetailCard from './CarrierDetailCard';
import { Bag } from '../domain/Bag';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HttpInterceptor from '../services/HttpInterceptor';
import styled from 'styled-components';
import { useAuth } from '../domain/AuthContext';
import { LOGIN, SHOWINTEREST } from './NavigationUrl';
import UserImage from './utils/UserImage';

const PreferIconImage = styled.img`
  width: 50px; /* Adjusted size for icons */
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 10px 0; /* Horizontal and bottom margin for spacing */
`;

const BagDetail: React.FC = () => {
  const { bagId } = useParams();
  const [bag, setBag] = useState<Bag>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const [showContactButton, setShowContactButton] = useState<boolean>(true);
  const [isSticky, setIsSticky] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollY = window.scrollY;

      console.log("scrolly " , scrollY);
      console.log("windowHeight " , windowHeight);
     console.log("documentHeight " , documentHeight);



      if (scrollY + windowHeight <= documentHeight - 60) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!bagId) {
      setLoading(false);
      setError("Bag ID can't be empty.");
      return;
    }

    HttpInterceptor.get(`/web/api/public/bag/${bagId}`)
      .then(resp => {
        setBag(resp.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch users');
        setLoading(false);
      });
  }, [bagId]);

  useEffect(() => {
    if (bag) {
      bag.carrier.username === user?.username
        ? setShowContactButton(false)
        : setShowContactButton(true);
    }
  }, [bag, user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading bag details: {error}</div>;
  if (!bag) return <div>Bag not found</div>;

  const handleContactHost = (bag: Bag) => {
    if (!isAuthenticated) {
      navigate(LOGIN);
    } else {
      navigate(SHOWINTEREST, { state: { currentBag: bag } });
    }
  };

  return (
    <div className="container">
      <h1 className="title">
        {bag.originCity} To {bag.destinationCity}
      </h1>
      <div style={{ marginTop: '20px' }} className="user-info">
        <UserImage src={bag.carrier?.profileurl} />
        <div className="name-container">
          <span className="checkbox">&#x2714;</span> {/* Checkmark symbol */}
          <span className="firstname">{bag.carrier?.firstname || 'Guest'} </span>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <p className="date">
          <strong>Going on: </strong>{bag.journeydate}
        </p>
      </div>
      <div style={{ marginTop: '10px' }}>
        <p><strong>Descriptions</strong></p>
        <p>{bag.description ? bag.description : 'N/A'}</p>
      </div>
      <div>
        <p><strong>Preferable Items that I can carry</strong></p>
        <div className="key-value-container">
          {Object.entries(bag.preferItems).map(([key, value]) => (
            <div key={key} className="key-value-pair">
              <span className="key">
                <PreferIconImage
                  src={`/images/icons/${key.replace(/\s+/g, '').toLowerCase()}.png?v=${new Date().getTime()}`}
                  alt={`${key} icon`}
                  title={key} // Tooltip showing the item name
                />
                {key}
              </span>
              <span className="value">
                {typeof value === 'boolean' ? (
                  <span className={`status-icon ${value ? 'checked' : 'unchecked'}`}>
                    {value ? '✓' : '✗'}
                  </span>
                ) : (
                  value
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: '15px' }}>
        <p><strong>Special Instructions</strong></p>
        <p>{bag.notesToSender ? bag.notesToSender : 'N/A'}</p>
      </div>
      <CarrierDetailCard
        name={bag.createdBy}
        bio={`${bag.createdBy} is an experienced traveler with a passion for providing great experiences`}
        photo={bag.carrier.profileurl}
        joineddate={bag.carrier.joinedDate}
        reviews="★★★★"
      />
      {/* Sticky button at the bottom */}
      {isSticky ? (
        <button
          onClick={() => handleContactHost(bag)}
          className="contact-button"
        >
          Contact {bag.carrier?.firstname || 'Guest'}
        </button>
      ) : null}

      {/* Non-sticky button at the end */}
      {!isSticky && showContactButton && (
        <div style={{ marginTop: '20px' ,maxWidth:'400px' }}>
          <button onClick={() => handleContactHost(bag)} className="app-button">
            Contact {bag.carrier?.firstname || 'Guest'}
          </button>
        </div>
      )}
    </div>
  );
};

export default BagDetail;
