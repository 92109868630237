import React, { useEffect, useState } from 'react';
import { Bag } from '../domain/Bag';
import BagCard from './BagCard';
import { getALLBags, getCitySuggestions } from '../services/bagservice';
import { FaSearch } from 'react-icons/fa';
import '../css/publicbagslist.css';
import ConfirmationMessage from './ConfirmationMessage';
import { City } from '../domain/CityOptions';

const BagList: React.FC = () => {
  const [bags, setBags] = useState<Bag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [cities,setCities] = useState<City[]>([]);

  useEffect(() => {
    localStorage.removeItem('navigationContext');
    console.log("clear bag");
    getALLBags().then(data => {
      console.log(data);
      
      // Sort bags by journeyDate
      const sortedBags = data.sort((a: Bag, b: Bag) => {
        return new Date(a.journeydate).getTime() - new Date(b.journeydate).getTime();
      });

      setBags(sortedBags);
      setLoading(false);
    }).catch(error => {
      setError('Failed to fetch bags');
      console.log(error);
    });
  }, []);

  // Fetch city suggestions from the backend
  const fetchCitySuggestions = async () => {
    try {
      const cityData = await getCitySuggestions(); // Call your API
      setCities(cityData);
      return cityData; // Assuming this returns an array of city names
    } catch (error) {
      console.error('Failed to fetch city suggestions:', error);
      return [];
    }
  };

  useEffect(() => {
    fetchCitySuggestions();
  }, [])

  // Generate suggestions based on search query
  useEffect(() => {
    const getSuggestions = async () => {
      if (searchQuery) {
        const lowerCaseQuery = searchQuery.toLowerCase();
       // const allCities = await fetchCitySuggestions(); // Fetch cities from backend
        setSuggestions(cities.filter(city => city.name.toLowerCase().includes(lowerCaseQuery)));
      } else {
        setSuggestions([]);
      }
    };

    getSuggestions();
  }, [searchQuery, cities]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Filter bags based on the search query
  const filteredBags = bags.filter(bag =>
    bag.destinationCity.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="property-list">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search traveler by destination"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <button className="search-button">
          <FaSearch />
        </button>
      </div>

      {filteredBags.length > 0 ? (
        filteredBags.map(bag => (
          <BagCard key={bag.id} bag={bag} />
        ))
      ) : (
        <ConfirmationMessage
          message='No bags found for your destination' 
          showBackButton={false}
          showFrontButton={false} 
        />
      )}
    </div>
  );
};

export default BagList;
