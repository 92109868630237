export const HOME = '/home';
export const CREATE_BAG = '/createbag';
export const LOGIN = '/login';
export const OAUTH2LANDING = '/oauth2landing';
export const LISTBAGS = '/listbags';
export const BAGDETAIL='/bagdetail/:bagId';
export const BAGDETAILEMPTY='/bagdetail';
export const ACCOUNT="/account";
export const MY_BAG_LIST = "/mybags";
export const SHOWINTEREST= "/showinterest";
export const MY_REQUESTS = "/myrequests";
export const SIGN_UP = "/signup";
export const VERIFICATION = "/verify";

export const PRIVACYPOLICY='/privacypolicy';
export const ROOT = '/';