// src/Form.tsx
import React, { useEffect, useState } from 'react';
import '../css/showinterest.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../domain/AuthContext';
import AuthService from '../services/AuthService';
import { BagProvider, useBagContext } from '../domain/BagContext';
import { Bag } from '../domain/Bag';
import BagCard from './BagCard';
import { NavigationContext } from '../domain/NavigationContext';
import HttpInterceptor from '../services/HttpInterceptor';
import ConfirmationMessage from './ConfirmationMessage';
import { getMyBagsContentsRequest } from '../services/bagservice';
import { BagContent } from '../domain/BagContent';
import { MY_REQUESTS } from './NavigationUrl';
import { useLocation } from 'react-router-dom';


const ShowInterestForm: React.FC = () => {
  const [description, setDescription] = useState('');
  const [initialMessage, setInitialMessage] = useState('');
  const [created,setCreated] = useState(false);
  const[requests,setRequests] = useState<BagContent[]>([]);
  const[alredyConnected,setAlredyConnected] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get location without type argument
  const navigationContext = location.state as NavigationContext; // Assert the type


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ description, initialMessage });
    const requestForBagSpace = { 
        description : description,
        bagId : navigationContext?.currentBag?.uniqueId,
     }
    const postData = {
        bagContent: requestForBagSpace,
        messageToCarrier: initialMessage || '',
      };

      try {

        HttpInterceptor.post('/web/api/bags/showinterest', postData, {
            headers: { 'Content-Type': 'application/json' }
          }).then(response => {
            console.log(response.data);
           // alert(response.status)
           // alert(response.data)
            setCreated(true);
          }).catch(error => {
            console.log(error);
            setCreated(false);

         //   alert(error)

            throw error;
          })
      } catch (error) {
        console.error('Error:', error);
       // setSubmitStatus('error');
      //  goToMessageBox('There is error in creating bag. Please trg again. if error persist  contact us .');
      }
    

  };


  useEffect(() => {
 if (Array.isArray(requests) && navigationContext?.currentBag) {
  const accepted = requests?.filter(request => request.bag.uniqueId === navigationContext?.currentBag?.uniqueId).length;

 

    if(accepted > 0) {
      setAlredyConnected(true);
      navigate(MY_REQUESTS);
     }
    }
  }, [requests,navigationContext])

  useEffect(() => {

    const fetchBags = async () => {
        console.log("callng fethbags")
        try {
          const response = await getMyBagsContentsRequest();
          setRequests(response); // Assuming response is already of type BagContent[]
         
        } catch (err) {
           // setAlredyConnected(false);
        } finally {
            //setAlredyConnected(false);
        }
      };


   
        fetchBags();
    
  }, [navigationContext]);


    if (created) return <ConfirmationMessage
            message="Your request to book a bag space has been sent.
             You will receive email notification from traveler if he/she accepts it. 
             You can also send them message  if needed."
            frontButtonLabel="view my requests"
            backButtonLabel="No"
            showFrontButton={true}
            showBackButton={false}
            frontRoute="/listbags" // Replace with your desired route
            backRoute="/previous-page" // Replace with your desired route
    />


  return (
    
    <div className="form-container">
      <h3 className="page-title">Connect with traveler</h3>
      <div>
        { navigationContext?.currentBag? (
          <BagCard key={navigationContext?.currentBag.id} bag={navigationContext?.currentBag} />
        ): (null)}
      </div>
      <form onSubmit={handleSubmit}>
        <label className="label" htmlFor="description">Tell us what you want to send?  <span style={{ color: 'red' }}>*</span></label>
        <textarea
          className="input"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Please let traveler know what items , size of the items , dimesion you want to send."
          required // This makes the description field mandatory
        />
        <label className="label" htmlFor="initialMessage">Initial Message</label>
        <textarea
          className="input"
          id="initialMessage"
          value={initialMessage}
          onChange={(e) => setInitialMessage(e.target.value)}
          placeholder="Enter your initial message..."
          required
        />
        <button className="button" type="submit">Send your request</button>
      </form>
     


    </div>
  );
};

export default ShowInterestForm;
