// MessageBox.tsx
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCopy, FaWhatsapp, FaFacebookF, FaEnvelope } from 'react-icons/fa'; // Import icons
import '../css/messagebox.css'; // Import custom CSS for styling
import HttpInterceptor from '../services/HttpInterceptor';
import { Bag } from '../domain/Bag';
import BagCard from './BagCard';

interface MessageBoxProps {
  message?: string;
  showBackButton?: boolean;
  showFrontButton?: boolean;
  frontButtonLabel?: string;
  backButtonLabel?: string;
  bagId?: string;
  backRoute?: string;
  frontRoute?: string;
}

const MessageBox: React.FC = () => {
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [bagDetail, setBagDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const {
    message = 'Default message',
    bagId = '',
    showBackButton = false,
    showFrontButton = false,
    frontButtonLabel,
    backButtonLabel,
    backRoute = '/',
    frontRoute = '/'
  } = location.state as MessageBoxProps;

  const handleBack = () => {
    navigate(backRoute); // Navigate to the back route
  };

  const handleFront = () => {
    navigate(frontRoute); // Navigate to the front route
  };

  useEffect(() => {

    if (!bagId) {
      // If bagid is not present, set error state and stop further execution
      setLoading(false);
      setError("bags id can;t be empty.");
      return;
    }
   
    HttpInterceptor.get(`/web/api/public/bag/${bagId}`)
    .then(resp => {
        console.log(resp.data);
        setBagDetail(resp.data);
        setLoading(false);
    }).catch( error => {
        setError('Failed to fetch users');
        console.log(error);
        setLoading(false);

    })


  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading bag details: {error}</div>;
  if (!bagDetail) return <div>Bag not found</div>;
  const bag : Bag = bagDetail;


  const handleShare = (platform: string) => {
    const url =   `${baseUrl}bagdetail/${bagId}`;
    // Get the current URL
    switch (platform) {
      case 'copy':
        navigator.clipboard.writeText(url);
        alert('Link copied to clipboard!');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'email':
        window.open(`mailto:?subject=Check this out&body=${encodeURIComponent(url)}`, '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <div className="message-box">
      <p className="message-text">Congratulation !! Your bag with following details created. You can share with friend.</p>

      <div>
      <BagCard key={bag.id} bag={bag} />
      </div>

      {/* <div className="button-group">
        {showBackButton && (
          <button onClick={handleBack} className="back-button">
            {backButtonLabel}
          </button>
        )}
        {showFrontButton && (
          <button onClick={handleFront} className="front-button">
            {frontButtonLabel}
          </button>
        )}
      </div> */}
      <div style={{ marginTop: '20px' }} className="share-icons">
        <FaCopy className="share-icon" onClick={() => handleShare('copy')} title="Copy Link" />
        <FaWhatsapp className="share-icon" onClick={() => handleShare('whatsapp')} title="Share on WhatsApp" />
        <FaFacebookF className="share-icon" onClick={() => handleShare('facebook')} title="Share on Facebook" />
        <FaEnvelope className="share-icon" onClick={() => handleShare('email')} title="Share via Email" />
      </div>
    </div>
  );
};

export default MessageBox;
