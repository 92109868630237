import React from 'react';

interface TimeDisplayProps {
  utcTime: string; // The UTC time as a string
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({ utcTime }) => {
  // Convert the UTC time string to a Date object
  const date = new Date(utcTime);

  // Format the date and time
  const formattedDate = date.toLocaleDateString('en-GB'); // 'dd/mm/yyyy'
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // 24-hour format

  return (
    <>
    {formattedDate}  {formattedTime}
    </>
  );
};



export default TimeDisplay;
