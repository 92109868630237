import React from 'react';
import { BagContent } from '../domain/BagContent';
import '../css/bagrequeststatus.css'; // Import the CSS file
import { capitalizeFirstLetter } from '../utlities/formateText';
import { Button } from 'react-bootstrap';
import TimeDisplay from './utils/TimeDisplay';
import UserImage from './utils/UserImage';


interface BagRequestStatusProps {
  bagContent: BagContent;
  showViewdetail? : boolean;
  showDescription? :boolean;
  showSenderName?: boolean;
}

interface BagRequestStatusState {}

class BagRequestStatus extends React.Component<BagRequestStatusProps, BagRequestStatusState> {
  constructor(props: BagRequestStatusProps) {
    super(props);
    this.state = {};
  }

 


  componentDidMount() {
    // You can fetch additional data here if needed
  }

  render() {
    const { bagContent , showViewdetail ,showDescription , showSenderName } = this.props;

    return (
      <div className="request-details">
        <hr className="divider" />
        <div className="request-content">
          {showSenderName &&         
            <p>
            
            <UserImage 
            src={bagContent.sender?.profileurl}  
            style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}/>

            
            {bagContent.sender.firstname} </p>
        }
          <p><strong>Inquired on :</strong> <TimeDisplay utcTime={bagContent.requestedDateTime} /> </p>
          <p><strong>Status:</strong> {bagContent.status && capitalizeFirstLetter(bagContent.status)}</p>
          {showDescription &&   <p><strong>Description:</strong> {bagContent.description }</p> }

        {showViewdetail &&  <Button onClick={() => window.location.href = `/viewrequest/${bagContent.requestId}` } className="app-button">View Request Detail</Button> }
        </div>
      </div>
    );
  }
}

export default BagRequestStatus;
