import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../domain/AuthContext';
import { useParams } from 'react-router-dom';
import { BagContent } from '../domain/BagContent';
import { User } from '../domain/user';
import '../css/chat.css'; // Import the CSS file
import TimeDisplay from './utils/TimeDisplay';
import HttpInterceptor from '../services/HttpInterceptor';
import UserImage from './utils/UserImage';

interface RequestStatsProps {
  request: BagContent;
}

interface Message {
  id: number;
  requestid: string;
  sender: User;
  receiver: User;
  content: string;
  dateTime: string;
}

const Chat: React.FC<RequestStatsProps> = ({ request }) => {
  const [bagContent, setBagContent] = useState<BagContent | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageContent, setMessageContent] = useState('');
  const { user } = useAuth();
  const { requestId } = useParams<{ requestId: string }>();
  const[scroll,setScroll] = useState<Boolean>(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setBagContent(request);
    fetchMessages();
  }, [request]);

  const fetchMessages = async () => {
    const response = await HttpInterceptor.get(`/web/api/chat/messages/${request.requestId}`);
    setMessages(response.data);
  };

  const sendMessage = async () => {
    if (messageContent.trim()) {
      await HttpInterceptor.post('/web/api/chat/messages', {
        sender: user?.username,
        receiver: bagContent?.sender.username,
        content: messageContent,
        read: 1,
        requestid: bagContent?.requestId,
      });
      setMessageContent('');
      await fetchMessages();
      setScroll(!scroll);
    }
  };

  // Scroll to bottom when the messages state is updated
  useEffect(() => {
   
       scrollToBottom();
  }, [scroll]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message) => (
          <div 
            key={message.id} 
            className={`chat-message ${message.sender.username === user?.username ? 'sent' : 'received'}`}
          >

       <UserImage src={message.sender.username === user?.username ? user?.pictureUrl : message.sender.profileurl}  style={{ width: '40px', height: '40px', borderRadius: '40%', marginRight: '10px' }}/>

          
            <div className="chat-message-content">
              <div className="chat-message-header">
                <span>{message.sender.username === user?.username ? user.firstname : message.sender.firstname}</span>
                <span className="chat-timestamp">{message.dateTime && <TimeDisplay utcTime={message.dateTime} />}</span>
              </div>
              <div className="chat-message-text">{message.content}</div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="Type a message..."
          className="chat-input"
          onKeyPress={handleKeyPress}
        />
        <button onClick={sendMessage} className="chat-send-button">Send</button>
      </div>
    </div>
  );
};

export default Chat;
