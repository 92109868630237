// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyADVeO0Jl0IRW37OD8QkpeVDQkNp1VjcJE",
    authDomain: "extrabagspace-6c610.firebaseapp.com",
    projectId: "extrabagspace-6c610",
    storageBucket: "extrabagspace-6c610.appspot.com",
    messagingSenderId: "414847406106",
    appId: "1:414847406106:web:63db1b7bf04d38597501a3",
    measurementId: "G-5EDLT5Q7CM"
  };

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestFirebaseNotificationPermission = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: 'BBVs17TBTCcpQZAl9bx-P8xphRySoWmiLczmmQ2D8DScWGWAbqgCHKxDazym2NoDShDlXaw63cFYFHLT_f6QGPA' });
    if (token) {
      console.log('Firebase token:', token);
      return token;
    }
  } catch (err) {
    console.error('Failed to get token:', err);
    throw err;
  }
};

export const checkNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.error('Unable to get permission to notify.');
    }
  };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
