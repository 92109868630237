import React, { useEffect, useState } from 'react';
import Header from './layout/hearder';
import Body from './layout/body';
import Footer from './layout/footer';
import { AuthProvider } from './domain/AuthContext';
import './App.css'; // Import CSS file for styling
import AuthFooter from './layout/authfooter';
import Header2 from './layout/header2';
import PageBottom from './layout/pagebottom';
import GoogleTypeHeader from './layout/GoogleTypeHeader';
import { onMessageListener, requestFirebaseNotificationPermission ,checkNotificationPermission} from './components/utils/firebase';


// src/App.tsx

const App: React.FC = () => {
  

  return (
    
          <AuthProvider>
              <GoogleTypeHeader />
              <Body />
              <PageBottom/>
              <AuthFooter/>
      </AuthProvider>


  );
};

export default App;

