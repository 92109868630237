import React, { useState } from 'react'; 
import styled from 'styled-components';
import Sidebar from './Sidebar';
import UserImage from '../components/utils/UserImage';
import { useAuth } from '../domain/AuthContext';

// Define the styled components
const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
`;

const Logo = styled.img`
  height: 60px; /* Adjust size as needed */
  width: auto;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px; /* Width of the button */
  height: 24px; /* Height of the button */
  
  & div {
    width: 100%;
    height: 3px; /* Thickness of the bars */
    background-color: #333; /* Bar color */
  }
`;

const GoogleTypeHeader: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const defaultUserIcon = "/images/default-user-icon.png"; // Path to your default user icon
  const { isAuthenticated, user, logout } = useAuth();

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <>
      <HeaderContainer>
        <Logo onClick={() => window.location.href = '/'} src="/images/logo-png.png" alt="Company Logo" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {isAuthenticated && (
          <UserImage 
             onClick={toggleSidebar} 
          /> )}

          <MenuButton onClick={toggleSidebar}>
            <div />
            <div />
            <div />
          </MenuButton>
        </div>
      </HeaderContainer>
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default GoogleTypeHeader;
