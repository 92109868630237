import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../domain/AuthContext';
import { useParams } from 'react-router-dom';
import { BagContent } from '../domain/BagContent';
import { User } from '../domain/user';
import '../css/chat.css'; // Import the CSS file
import BagCard from './BagCard';
import BagRequestStatus from './BagRequestStatus';
import Chat from './Chat';
import HttpInterceptor from '../services/HttpInterceptor';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { NavigationContext } from '../domain/NavigationContext';
import { LOGIN } from './NavigationUrl';
import { updateBagRequestStatus } from '../services/bagservice';
import { ACCEPTED_STATUS, REJECTED_STATUS } from '../domain/State';

interface Message {
  id: number;
  requestid: string;
  sender: User;
  receiver: User;
  content: string;
  dateTime: string;
}



const ViewRequest: React.FC = () => {
  const [bagContent, setBagContent] = useState<BagContent | null>(null);
  const { requestId } = useParams<{ requestId: string }>();
  const { isAuthenticated, user, logout } = useAuth();
  const isFirstRender = useRef(true); // Ref to track the first render
  const [showActionButtons,setShowActionButtons] = useState<Boolean>();
  const[contentStatus,setContentStatus] = useState<string>();

  const navigationContext: NavigationContext = {
    currentBag: null,
    nextRoute: null,
    previousRoute: null
  };

  useEffect(() => {
    const fetchBagContent = async () => {
      const response = await HttpInterceptor.get(`/web/api/bags/getrequest/${requestId}`);
      setBagContent(response.data);
    };

    if (AuthService.isAuthenticated()) {
      requestId && fetchBagContent();
    } else {
      navigationContext.nextRoute = "/viewrequest/" + requestId;
      sessionStorage.setItem('navigationContext', JSON.stringify(navigationContext));   
      window.location.href = LOGIN;
    }
  }, [requestId]);


  useEffect(() => {
    
    if (bagContent) {
       if ( bagContent.bag.carrier.username === user?.username ) {
        setShowActionButtons(true);
       }else{
        setShowActionButtons(false);
       }

       bagContent.status && setContentStatus(bagContent.status);

    }


  }, [bagContent,user])

  const handleApproveButtonClick = () => {
    // Add your functionality here
    console.log('Left button clicked');

   const returnValue =  bagContent && updateBagRequestStatus(bagContent.requestId, ACCEPTED_STATUS);

   if (returnValue) {
        setContentStatus(ACCEPTED_STATUS);
        bagContent.status=ACCEPTED_STATUS;
   }

   
  };

  const handleRejectButtonClick = () => {
    // Add your functionality here
    console.log('Right button clicked');
    setContentStatus("rejected");

    const returnValue =  bagContent && updateBagRequestStatus(bagContent.requestId, REJECTED_STATUS);
    if (returnValue) {
            setContentStatus(REJECTED_STATUS); 
            bagContent.status=REJECTED_STATUS;

        
    }



  };



  return (
    <div className="chat-bag-container">
      <h3 className="page-title">Request detail for bag space</h3>
  
      {bagContent && (
        <>
          <div className="bag-card">
            <hr className="section-divider" />
            <BagCard bag={bagContent.bag} />
          </div>
  
          <div className="bag-request-status">
            <h4 className="section-heading">Bag space request </h4>
            <hr className="section-divider" />
            <BagRequestStatus
              bagContent={bagContent}
              showViewdetail={false}
              showSenderName={true}
              showDescription={true}
            />
          </div>
  
          <div className="chat-container">
            <h4 className="section-heading">Messages</h4>
            <hr className="section-divider" />
            <Chat request={bagContent} />
          </div>
  
          {showActionButtons && (
            <div className="button-container">
              <button disabled={contentStatus === ACCEPTED_STATUS} className="left-button" onClick={handleApproveButtonClick}>
                Approve Request
              </button>
              <button disabled={contentStatus === REJECTED_STATUS} className="right-button" onClick={handleRejectButtonClick}>
                Reject Request
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
  
};

export default ViewRequest;
