import React, { useState } from 'react';
import '../css/verification.css'; // Import CSS file for styling

const VerificationPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({}); // Error state

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};

    // Validation
    if (!email) newErrors.email = "Email is required.";
    if (!code) newErrors.code = "Verification code is required.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set errors
      return;
    }

    console.log("Email:", email);
    console.log("Verification Code:", code);
    // Add your verification logic here

    // Clear the form and errors
    setEmail('');
    setCode('');
    setErrors({});
  };

  return (
    <div className="verification-container">
      <div className="verification-form">
        <h1 className="verification-title">Verify Your Email</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (e.target.value) {
                  setErrors((prev) => ({ ...prev, email: '' }));
                }
              }}
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          <div className="input-group">
            <input
              type="text"
              placeholder="Verification Code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (e.target.value) {
                  setErrors((prev) => ({ ...prev, code: '' }));
                }
              }}
              required
            />
            {errors.code && <span className="error-message">{errors.code}</span>}
          </div>

          <button type="submit" className="verification-button">Verify</button>
        </form>
      </div>
    </div>
  );
};

export default VerificationPage;
