import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';
import HttpInterceptor from '../services/HttpInterceptor';
import { useAuth } from '../domain/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CREATE_BAG, MY_BAG_LIST, ROOT } from './NavigationUrl';
import { NavigationContext } from '../domain/NavigationContext';

const Oauth2LandingPage = () => {

    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const[isLoggedin,setLoggedIn] = useState<Boolean>();
    const [error, setError] = useState(null);
    const { login } = useAuth();
    const navigate = useNavigate();

    const [navigationContext, setNavigationContext] = useState<NavigationContext | null>(null); // Initialize state here

    useEffect(() => {
        console.log("Inside useeffect[] for oauth2 landing page");
        const data = sessionStorage.getItem('navigationContext');
        if (data) {
            console.log("navingation context found on landing page " , data)
            setNavigationContext(JSON.parse(data));
        }else{
            console.log("no navation cntext foound on landing page");
        }

        axios.get('/web/api/user/')
        .then(response => {
            AuthService.login(response.data);
            login(response.data);
            console.log(response.data);
            setLoggedIn(true);
        }).catch( error => {
         console.log(error);
         setLoading(false);
         setLoggedIn(false);
         navigate(ROOT);
        })

    }, []);

    useEffect(() => {
        console.log("Inside useeffect[navigationcontext] for oauth2 landing page");
        setLoading(true);

        if(isLoggedin ) {
            const route  = navigationContext?.nextRoute?navigationContext?.nextRoute:MY_BAG_LIST;
            sessionStorage.removeItem('navigationContext');
            navigate(route, { state: navigationContext });
   
             // navigate(route)
             setLoading(false);
        }
      }, [navigationContext,isLoggedin]);
      if (loading) return <div>Loading...</div>;
      
  return (
   <div></div>

  );
};

export default Oauth2LandingPage;