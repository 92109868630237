import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { User } from './user';
import AuthService from '../services/AuthService';


interface AuthContextType {
  isAuthenticated: boolean;
  user?: User;
  login: (user: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const login = (user: User) => {
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = () => {
    AuthService.handleSignOut();
    sessionStorage.removeItem('navigationContext');
    setIsAuthenticated(false);
    setUser(undefined);
  };

  useEffect( () => {
    console.log("use effect on authcontext.tsx called");
    AuthService.isAuthenticated() ? setIsAuthenticated(true) : setIsAuthenticated(false);
    AuthService.isAuthenticated() ? setUser(AuthService.getUserInformation()) : setUser(undefined);
  },[]) 

  // useEffect( () => {
  //   console.log("use effect on authcontext.tsx called");
  //   AuthService.isAuthenticated() ? setIsAuthenticated(true) : setIsAuthenticated(false);
  //   AuthService.isAuthenticated() ? setUser(AuthService.getUserInformation()) : setUser(undefined);
  // },[isAuthenticated,user]) 

  

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
