import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CreateBagFormComponent from '../components/CreateBag';
import LoginPage from '../components/login';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Oauth2LandingPage from '../components/Oauth2LandingPage';
import BagList from '../components/BagList';
import '../css/table.css'; // Import CSS file for styling
import '../css/bagslist.css'; // Import CSS file for styling
import { ACCOUNT, BAGDETAIL, BAGDETAILEMPTY, CREATE_BAG, LISTBAGS, 
  LOGIN, MY_BAG_LIST, MY_REQUESTS, OAUTH2LANDING, PRIVACYPOLICY, ROOT, SHOWINTEREST, 
  SIGN_UP, 
  VERIFICATION} from '../components/NavigationUrl';
import BagDetail from '../components/BagDetail';
import PrivacyPolicy from '../components/PrivacyPolicy';
import MessageBox from '../components/MessageBox';
import Footer from './footer';
import AccountPage from '../components/AccountPage';
import Landing from '../components/landing';
import MyBagList from '../components/MyBagList';
import ShowInterestForm from '../components/ShowInterest';
import BagCard from '../components/BagCard';
import BagRequestStatus from '../components/BagRequestStatus';
import Chat from '../components/Chat';
import ViewRequest from '../components/ViewRequest';
import MyRequestsForBagSpace from '../components/MyRequestsForBagSpace';
import BagContentRequests from '../components/BagContentsRequests';
import SignUpPage from '../components/SignUp';
import VerificationPage from '../components/verifyuser';

const Body: React.FC = () => {
  const abc = () => {
  }
  return (
    <Container>
      <Row className="my-4">
        <Col>
        <BrowserRouter>
          <Routes>
          <Route path={LOGIN} element={<LoginPage />} />
          <Route path={CREATE_BAG} element={<CreateBagFormComponent />} />
          <Route path={OAUTH2LANDING} element={<Oauth2LandingPage />} />
          <Route path={LISTBAGS}element={<BagList/>} />
          <Route path={BAGDETAIL} element={<BagDetail/>}/>
          <Route path={BAGDETAILEMPTY} element={<BagDetail/>}/>
          <Route path={ACCOUNT} element={<AccountPage />} />
          <Route path={ROOT} element={<Landing />} />
          <Route path={PRIVACYPOLICY} element={<PrivacyPolicy/>} />
          <Route path={MY_BAG_LIST} element={<MyBagList/>} />
          <Route path={SHOWINTEREST} element={<ShowInterestForm/>} />
          <Route path={MY_REQUESTS} element={<MyRequestsForBagSpace/>} />
          <Route path="/message" element={<MessageBox />} />
          <Route path={SIGN_UP} element={<SignUpPage />} />
          
          <Route path={VERIFICATION} element={<VerificationPage />} />

          <Route path="/viewrequest/:requestId" element={<ViewRequest />} />
          <Route path="/bagcontents/:currentBagId" element={<BagContentRequests />} />

        </Routes>
      </BrowserRouter>     
   </Col>
      </Row>
    </Container>
  );
};

export default Body;
