import React from 'react';
import styled from 'styled-components';
import { FaHome, FaUser, FaCog, FaEnvelope, FaPlus, FaSignInAlt, FaLuggageCart, FaWindows, FaSearch } from 'react-icons/fa'; // Importing icons
import { useAuth } from '../domain/AuthContext';
import AuthService from '../services/AuthService';
import { FaSignOutAlt } from 'react-icons/fa';
import { CREATE_BAG, LISTBAGS, LOGIN, MY_BAG_LIST, MY_REQUESTS } from '../components/NavigationUrl';
import LoginPage from '../components/login';
import { NavigationContext } from '../domain/NavigationContext';
import UserImage from '../components/utils/UserImage';
interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const SidebarContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #fff; /* Changed background to white */
  color: #000; /* Changed text color to black */
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it's above other content */
  aria-hidden: ${({ isOpen }) => (isOpen ? 'false' : 'true')};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #000; /* Changed button color to black */
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  /* Optional: Add focus styling for better accessibility */
  &:focus {
    outline: 2px solid #000; /* Changed focus outline color to black */
    outline-offset: 2px;
  }
`;

const SidebarTitle = styled.div`
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 3px solid #007bff; /* Blue line */
  color: #000; /* Title color */
`;

const MenuItems = styled.ul`
  list-style: none;
  padding: 2rem;
  margin: 0;
  padding-left: 0;
`;

const MenuItem = styled.li`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  /* Add line separation */
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  cursor: pointer; /* Indicate that menu items are clickable */
  &:last-child {
    border-bottom: none;
  }
`;

const MenuItemIcon = styled.div`
  margin-right: 1.5rem; /* Increased space between icon and text */
  font-size: 1.2rem;
`;



const AppIcon = styled.img`
  width: 5rem; /* Set width of the user image */
  height: 4rem; /* Set height of the user image */
  border-radius: 50%; /* Make the image circular */
  margin-right: 1rem; /* Space between image and text */
`;

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  // Effect to manage focus when sidebar opens
  const { isAuthenticated, user, logout } = useAuth();

  const navigationContext: NavigationContext = {
    currentBag: null,
    nextRoute: null,
    previousRoute: null

  };

  const createBag = () => {
    if (!isAuthenticated) {
    //  navigationContext.currentBag = bag;
    console.log("Setting up create bag context");
      navigationContext.nextRoute=CREATE_BAG;
      sessionStorage.setItem('navigationContext', JSON.stringify(navigationContext));
      window.location.href = LOGIN;
    }
      else {
    
        window.location.href = CREATE_BAG;

      }

};

  React.useEffect(() => {
    if (isOpen) {
      const closeButton = document.querySelector('button[aria-label="Close Sidebar"]') as HTMLButtonElement | null;
      closeButton?.focus();
    }
  }, [isOpen]);

  return (
    <SidebarContainer isOpen={isOpen} aria-hidden={!isOpen}>
      <CloseButton 
        onClick={onClose} 
        aria-label="Close Sidebar"
      >
        ×
      </CloseButton>
      <SidebarTitle>
      <AppIcon src="/images/logo-png.png" alt="User Profile" />
  </SidebarTitle>
      <MenuItems>
        
     { isAuthenticated && <MenuItem>
      <UserImage /> &nbsp;
                   {user?.firstname} &nbsp; {user?.lastname}
        </MenuItem> }
        
   
      {!isAuthenticated ? (
      <>
        <MenuItem onClick={() => window.location.href = LISTBAGS }>
        <MenuItemIcon>
        <FaSearch />
        </MenuItemIcon>
        Search Travelers
        </MenuItem>
        <MenuItem onClick={() => createBag() }>
        <MenuItemIcon><FaPlus /></MenuItemIcon>
        Offer Bag Space
        </MenuItem>

        <MenuItem onClick={() => window.location.href = LOGIN}>
          <MenuItemIcon><FaSignInAlt /></MenuItemIcon>
          Sign In
        </MenuItem>
        </>

      ) : ( 
        <>
        
      
        <MenuItem onClick={logout}>
         <MenuItemIcon><FaSignOutAlt /></MenuItemIcon>
          Sign Out
         </MenuItem>
         </> 
        )
    }
      </MenuItems>
    </SidebarContainer>
  );
};

export default Sidebar;
