import React from 'react';
import Slider from 'react-slick';
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../css/landing.css'; // For custom styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const images = [
  { src: '/images/1.jpeg', description: 'Explore the vibrant city life in Ahmedabad!' },
  { src: '/images/2.jpeg', description: 'Experience the bustling streets of Mumbai!' },
  { src: '/images/3.jpg', description: 'Discover the charm of Surat!' },
];

const cardData = [
  { title: 'Ahmedabad', imageSrc: '/images/ahmedabad.jpg', content: '5+ bags going' },
  { title: 'Mumbai', imageSrc: '/images/mumbai.jpg', content: '3+ bags going' },
  { title: 'Surat', imageSrc: '/images/surat.jpg', content: '2+ bags going' },
];

const Landing: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,          // Enable autoplay
    autoplaySpeed: 9000,    // Set the interval for autoplay (15 seconds)
  };

  return (
    <div className="home-page">
      <div className="carousel-container">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="slide">
              <img src={image.src} alt={`Slide ${index + 1}`} className="slide-image" />
              <div className="slide-content">
                {index === 0 ? (
                  <>
                    <h2 className="slide-title">
                      Are you looking for air traveler to send your item  ?
                    </h2>
                    <p className="slide-description">
                      Our platform connects you with travelers who have extra bag space available. You can reach out to them and express your interest in shipping items with them.
                    </p>
                    <button 
                      className="slide-button" 
                      onClick={() => window.location.href = '/listbags'}
                    >
                      <FontAwesomeIcon icon={faSearch} className="search-icon" /> Connect with Travelers
                    </button>
                  </>
                ) : index === 1 ? (
                  <>
                    <h2 className="slide-title">
                      Traveling soon and have extra luggage space?
                    </h2>
                    <p className="slide-description">
                      Are you traveling with extra unused bag space? You can offer your luggage space and earn up to $950 per travel. Join us today and start offering your available luggage space. It is FREE!
                    </p>
                    <button 
                      className="slide-button" 
                      onClick={() => window.location.href = '/createbag'}
                    >
                       Offer your extra luggage space.
                    </button>
                  </>
                ) : (
                  <>
                    <h2 className="slide-title">
                      Why choose us?
                    </h2>
                    <div className="feature-list-container">
                      <ul className="feature-list">
                        <li className="feature-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span className="feature-text">You can select the items you're comfortable carrying in your travel bag.</span>
                        </li>
                        <li className="feature-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span className="feature-text">You can choose the pickup and drop-off locations for the content based on your convenience.</span>
                        </li>
                        <li className="feature-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span className="feature-text">You can speak directly with the sender and determine the rate yourself.</span>
                        </li>
                        <li className="feature-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span className="feature-text">No fees.</span>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* Uncomment and use this if you want to display the city cards */}
      {/* <div className="cards-container">
        {cardData.map((card, index) => (
          <Card
            key={index}
            imageSrc={card.imageSrc}
            title={card.title}
            description={card.content}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Landing;
