import React from 'react';
import { Link } from 'react-router-dom';
import { Bag } from '../domain/Bag';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '../utlities/formateText';
import '../css/bagcard.css';
import { FaLuggageCart } from 'react-icons/fa';
import UserImage from './utils/UserImage';

interface BagCardProps {
  bag: Bag;
  children?: React.ReactNode; // Add children prop
}

const CardContainer = styled(Link)`
  text-decoration: none; /* Remove underline from link */
  color: inherit; /* Use inherited color for text */
  border: 1px solid #eaeaea; /* Light border */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure child elements fit within rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease;
  margin: 20px 0; /* Space between cards */
  
  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Elevated shadow on hover */
  }
`;

const PropertyCardContent = styled.div`
  padding: 16px; /* Padding inside the card */
`;

const PropertyCardTitle = styled.h3`
  font-size: 20px;
  margin: 0 0 8px; /* Spacing below the title */
  font-weight: bold; /* Make title bold */
`;

const PropertyCardPrice = styled.p`
  font-size: 16px;
  color: black; /* Use a prominent color for price */
  margin: 0 0 4px; /* Spacing below the price */
`;

const PropertyCardRating = styled.p`
  font-size: 14px;
  color: #777; /* Grey color for rating */
  display: flex;
  align-items: center; /* Center items vertically */
`;

const ChildCardContainer = styled.div`
  background-color: #f9f9f9; /* Light background for child card */
  border: 1px solid #eaeaea; /* Light border for child card */
  border-radius: 8px; /* Rounded corners */
  padding: 16px; /* Padding for child card */
  margin-top: 16px; /* Space above child card */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;

const BagCard: React.FC<BagCardProps> = ({ bag, children }) => {
  return (
    <CardContainer to={`/bagdetail/${bag.uniqueId}`} className="property-card-link">
      <PropertyCardContent >
        <PropertyCardTitle>{bag.originCity} To {bag.destinationCity}</PropertyCardTitle>
        <PropertyCardPrice style={{ marginTop: '20px' }}>
          <strong>Going on:</strong> {bag.journeydate}
        </PropertyCardPrice>
        <PropertyCardPrice>
          <strong>Status:</strong> {capitalizeFirstLetter(bag.bagStatus)}
        </PropertyCardPrice>
        <PropertyCardRating style={{ marginTop: '20px' }}>
          <UserImage src={bag.carrier?.profileurl}  style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}/>
          {bag.carrier?.firstname}
        </PropertyCardRating>
        <span>★★★★</span>

        {/* Render children as a card */}
        {children && (
          <ChildCardContainer>
            {children}
          </ChildCardContainer>
        )}
      </PropertyCardContent>
    </CardContainer>
  );
};

export default BagCard;
