import React, { useState } from 'react';
import { BagContent } from '../domain/BagContent';
import { getBagsContents, getMyBagsContentsRequest } from '../services/bagservice';
import BagCard from './BagCard';
import BagRequestStatus from './BagRequestStatus';
import ConfirmationMessage from './ConfirmationMessage';
import { LISTBAGS } from './NavigationUrl';

interface BagRequestStatusProps {
 // currentBagId: string;
}

interface BagContentRequestState {
  requests: BagContent[];
  loading: boolean;
  error: string | null;
  show: boolean | false;
}

class MyRequestsForBagSpace extends React.Component<BagRequestStatusProps, BagContentRequestState> {
  constructor(props: BagRequestStatusProps) {
    super(props);
    this.state = {
      requests: [],
      loading: true,
      error: null,
      show: false
    };
  }

  componentDidMount() {
    this.fetchRequests();
  }

  async fetchRequests() {
    try {
      const requestsData = await getMyBagsContentsRequest();
      this.setState({ requests: requestsData, show: requestsData?.length > 0 ? true : false, loading: false });
     
    } catch (error) {
      this.setState({ error: 'Failed to load requests', loading: false });
    }
  }

  render() {
    const { loading, error, requests , show } = this.state;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!show) return  <ConfirmationMessage 
    message='You have not shown interest to any available bag space yet.'  
    frontButtonLabel="View available bag spaces"  
    showFrontButton={true}
    frontRoute={LISTBAGS}/>
  
    return (
      <div>
        <h3 className='page-title'>You requests to send items</h3>
        
        <div className="property-list">
    
    {show && requests.map((request) => (
     <div
     key={request.bag.id}
     style={{
       margin: 0,
       border: '1px solid #000000', // Black border
       borderRadius: '8px', // Optional: Rounded corners
       overflow: 'hidden', // Ensures child elements fit within the border
     }}
   >
     <BagCard bag={request.bag} />
     <BagRequestStatus 
      bagContent={request} 
      showSenderName={true} 
      showViewdetail={true} />
   </div>
   
    ))}
  </div>

      </div>
    );
  }
}

export default MyRequestsForBagSpace;
