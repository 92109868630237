import React, { useState } from 'react';
import '../css/login.css'; // Import CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleGoogleSignIn = () => {
    const redirectUrl = process.env.REACT_APP_OAUTH2_LOGIN_GOOGLE_API_END_POINT;
    console.log("Sign in with Google clicked");
    if (redirectUrl) window.location.href = redirectUrl;
  };

  const handleFacebookSignIn = () => {
    const redirectUrl = process.env.REACT_APP_OAUTH2_LOGIN_FACEBOOK_API_END_POINT;
    console.log("Sign in with Facebook clicked");
    if (redirectUrl) window.location.href = redirectUrl;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Username:", username);
    console.log("Password:", password);
    // Add your login logic here
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1 className="login-title">Welcome to Extra Bag Space</h1>
        <div className="social-buttons">
          <button className="social-button google-button" onClick={handleGoogleSignIn}>
            <FontAwesomeIcon icon={faGoogle} className="social-icon" />
            <span>Continue with Google</span>
          </button>
        </div>
        <div className="social-buttons">
          <button className="social-button facebook-button" onClick={handleFacebookSignIn}>
            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
            <span>Continue with Facebook</span>
          </button>
        </div>

      
 {/*
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-button">Sign In</button>
        </form>
        <div className="signup-link">
          <span>Don't have an account? </span>
          <a href="/signup">Sign up</a>
        </div>
        */}
      </div>
    </div>
  );
};

export default LoginPage;
