import React from 'react';
import { FaHome, FaEnvelope, FaPlane, FaUser, FaPlus, FaSignInAlt, FaSearch, FaLuggageCart, FaList, FaBoxes } from 'react-icons/fa';
import '../css/authfooter.css';
import { useAuth } from '../domain/AuthContext';
import { Link } from 'react-router-dom';
import { CREATE_BAG, LOGIN, MY_BAG_LIST, MY_REQUESTS } from '../components/NavigationUrl';
import { NavigationContext } from '../domain/NavigationContext';

const AuthFooter: React.FC = () => {
    const { isAuthenticated } = useAuth();

    const navigationContext: NavigationContext = {
        currentBag: null,
        nextRoute: null,
        previousRoute: null
    };

    const createBag = () => {
        if (!isAuthenticated) {
            navigationContext.nextRoute = CREATE_BAG;
            sessionStorage.setItem('navigationContext', JSON.stringify(navigationContext));
            window.location.href = LOGIN;
        } else {
            window.location.href = CREATE_BAG;
        }
    };

    return (
        <footer className="footer">
            <div className="footer-icon" onClick={() => window.location.href = '/listbags'}>
                <FaSearch className="icon" />
                <span>Search Travelers</span>
            </div>
            <div className="footer-icon" onClick={createBag}>
                <FaPlus className="icon" />
                <span>Offer Bag Space</span>
            </div>
            {isAuthenticated ? (
                <>
                    <div className="footer-icon" onClick={() => window.location.href = MY_REQUESTS}>
                        <FaBoxes className="icon" />
                        <span>My Items</span>
                    </div>
                    <div className="footer-icon" onClick={() => window.location.href = MY_BAG_LIST}>
                        <FaLuggageCart className="icon" />
                        <span>My Travels</span>
                    </div>
                </>
            ) : (
                <div className="footer-icon" onClick={() => window.location.href = LOGIN}>
                    <FaSignInAlt className="icon" />
                    <span>Sign In</span>
                </div>
            )}
        </footer>
    );
};

export default AuthFooter;
