// MessageBox.tsx
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCopy, FaWhatsapp, FaFacebookF, FaEnvelope } from 'react-icons/fa'; // Import icons
import '../css/messagebox.css'; // Import custom CSS for styling
import HttpInterceptor from '../services/HttpInterceptor';
import { Bag } from '../domain/Bag';
import BagCard from './BagCard';

import '../css/confirmationmessage.css';
import { title } from 'process';


interface ConfirmationMessageProps {
  title?:string 
  message?: string ;
  showBackButton?: boolean ;
  showFrontButton?: boolean ;
  frontButtonLabel?: string ;
  backButtonLabel?: string ;
  backRoute?: string ;
  frontRoute?: string ;
}

const ConfirmationMessage: React.FC<ConfirmationMessageProps> = ({
    message,
    showBackButton,
  showFrontButton,
    frontButtonLabel,
  backButtonLabel,
  backRoute,
  frontRoute,
  title
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

 
  const handleBack = () => {
    backRoute && navigate(backRoute); // Navigate to the back route
  };

  const handleFront = () => {
    frontRoute && navigate(frontRoute); // Navigate to the front route
  };



  return (
    <div className="confirmation-message-box">
      <p className="message">{message}</p>
      <div className="button-group">
      {showFrontButton && ( <button className="front-button" onClick={handleFront}>
          {frontButtonLabel}
        </button> )}
        {showBackButton  && (
          <button className="back-button" onClick={handleBack}>
            {backButtonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default ConfirmationMessage;
