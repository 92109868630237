import React from 'react';
import '../css/bagdetail.css';
import UserImage from './utils/UserImage';

interface Review {
  author: string;
  content: string;
}

interface CarrierDetailCardProps {
  name: string;
  bio: string;
  photo: string; // Add this prop
  joineddate: string;
  reviews: string;
}

const CarrierDetailCard: React.FC<CarrierDetailCardProps> = ({ name, 
    joineddate,  
    bio, 
    photo,
    reviews }) => {
  return (
    <div className="owner-card">
      <div className="owner-card-header">
      <UserImage src={photo}  style={{  marginRight: '10px' }}/>
      <div>
          <h2>About the traveler</h2>
          <p><strong>{name}</strong></p>
          <p> <strong>Joined since</strong> {joineddate}</p>
          <p>{bio}</p>
          <p className="property-card-rating">Rating: {reviews}</p>

        </div>
      </div>

    
    </div>
  );
};

export default CarrierDetailCard;
