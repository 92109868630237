import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../domain/AuthContext';



const Image = styled.img<{ style?: React.CSSProperties }>`
  border-radius: 40%; /* Round shape */
  height: 40px; /* Default size */
  width: 40px; /* Default size */
  cursor: pointer;
  ${({ style }) => style && { ...style }}; /* Apply custom styles */
`;

interface UserImageProps {
   src? : string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const DEFAULT_USER_ICON = "/images/icons/user-48.png";

const UserImage: React.FC<UserImageProps> = ({src,  onClick ,style}) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const { isAuthenticated, user, logout } = useAuth();

  const handleImageError = () => {
    setImageSrc(DEFAULT_USER_ICON); // Set to default icon if the image fails to load
  };

  useEffect(() => {
    if(src) {
        setImageSrc(src);
    }else if (isAuthenticated && user?.pictureUrl) {
        setImageSrc(user?.pictureUrl)
    }else {
        setImageSrc(DEFAULT_USER_ICON)
     }
    
  }, [user,isAuthenticated])


  return (
    <Image 
      src={imageSrc} 
      alt="User" 
      onError={handleImageError} // Handle image error
      onClick={onClick}
      style={style}
    />
  );
};

export default UserImage;
