import React, { useEffect, useState } from 'react';
import { BagContent } from '../domain/BagContent';
import { getBagById, getBagsContents, getMyBagsContentsRequest } from '../services/bagservice';
import BagCard from './BagCard';
import BagRequestStatus from './BagRequestStatus';
import { useParams } from 'react-router-dom';
import { Bag } from '../domain/Bag';



const BagContentRequests: React.FC = () => {
  // Define the state using useState
  const [requests, setRequests] = useState<BagContent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const { currentBagId } = useParams<{ currentBagId: string }>();
  const [bagdetail,setBagdetail] = useState<Bag>();

  // useEffect to fetch requests on mount
  useEffect(() => {
    currentBagId && fetchRequests(currentBagId);
    currentBagId && fetchBagDetail(currentBagId);
  }, [currentBagId]);

  const fetchBagDetail = async(currentBagId:string) => {
    try {
        const bagDetail = await getBagById(currentBagId);
        setBagdetail(bagDetail);
        //setShow(requestsData?.length > 0);
        setLoading(false);
      } catch (error) {
        setError('Failed to load requests');
        setLoading(false);
      }
  }

  const fetchRequests = async (currentBagId:string) => {
    try {
      const requestsData = await getBagsContents(currentBagId);
      setRequests(requestsData);
      setShow(requestsData?.length > 0);
      setLoading(false);
    } catch (error) {
      setError('Failed to load requests');
      setLoading(false);
    }
  };

  // Conditionally render based on loading, error, and show state
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!show) return <div>Bag does not get any request .</div>;

  return (
    <div>
      <h3 className='page-title'>Inquiries About Your Luggage Space</h3>
      <div className="property-list">

       { bagdetail &&  <BagCard bag={bagdetail} /> }
        
       <h4 className="section-heading">Requests from the Following Persons       </h4>

        {show && requests.map((request) => (
          <div
            key={request.bag.id}
            style={{
              margin: 0,
              border: '1px solid #000000', // Black border
              borderRadius: '8px', // Optional: Rounded corners
              overflow: 'hidden', // Ensures child elements fit within the border
            }}
          >
            <BagRequestStatus 
            bagContent={request} 
            showViewdetail={true}
            showDescription={false} 
            showSenderName={true}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BagContentRequests;
