import React from 'react';
import { Link } from 'react-router-dom';
import { Bag } from '../domain/Bag';
import styled from 'styled-components';
import BagRequestStats from './BagRequestStats';
import { capitalizeFirstLetter } from '../utlities/formateText';

interface BagCardProps {
  bag: Bag;
}

const CardContainer = styled(Link)`
  text-decoration: none; /* Remove underline from link */
  color: inherit; /* Use inherited color for text */
  border: 1px solid #eaeaea; /* Light border */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure child elements fit within rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease;
  padding: 16px; /* Added padding for consistency */
  margin: 10px 0; /* Spacing between cards */

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Elevated shadow on hover */
  }
`;

const PropertyCardTitle = styled.h3`
  font-size: 20px;
  margin: 0 0 8px; /* Spacing below the title */
  font-weight: bold; /* Make title bold */
`;

const PropertyCardDate = styled.p`
  font-size: 16px;
  color: black; /* Use a prominent color for date */
  margin: 0 0 4px; /* Spacing below the date */
`;

const PropertyCardStatus = styled.p`
  font-size: 14px;
  color: #555; /* Slightly muted color for status */
  margin: 10px 0; /* Spacing above and below */
`;

const MyBagCard: React.FC<BagCardProps> = ({ bag }) => {
  return (
    <CardContainer  to={`/bagdetail/${bag.uniqueId}`} className="property-card-link">
      <PropertyCardTitle>
        {bag.originCity} To {bag.destinationCity}
      </PropertyCardTitle>
      <PropertyCardStatus>
        <strong>Going on:</strong> {bag.journeydate}
      </PropertyCardStatus>
      <PropertyCardStatus>
        <strong>Status:</strong> {capitalizeFirstLetter(bag.bagStatus)}
      </PropertyCardStatus>
      <BagRequestStats currentBagId={bag.uniqueId} />
    </CardContainer>
  );
};

export default MyBagCard;
