// src/api.ts
import axios from 'axios';
import { Bag } from '../domain/Bag';
import HttpInterceptor from './HttpInterceptor';
import { City } from '../domain/CityOptions';


const API_URL = '/web/api/bags';

const PUBLIC_API_URL = '/web/api/public/';


export const getMyBags = async (): Promise<Bag[]> => {
  const response = await HttpInterceptor.get(API_URL + '/');
  return response.data;
};

export const getBagsContents = async(bagId :string) : Promise<any[]> => {
    const response =   await HttpInterceptor.get(API_URL + "/getrequests/" + bagId);
    return response.data;
}

export const getMyBagsContentsRequest= async() : Promise<any[]> => {
    const response =   await HttpInterceptor.get(API_URL + "/getmyrequests");
    return response.data;
}

export const registerForPushNotification = async(token:string , username:string): Promise<void> => {

  const postData = {
    token: token,
    username: username,
  };

  try {
    const response = await HttpInterceptor.post('/web/api/bags/registerpushnotification', postData, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    console.log("registed for the push notification successfully");
  } catch (error) {
    console.error('Error:', error);
   
  }



}

export const getALLBags = async (): Promise<Bag[]> => {
    const response = await axios.get(PUBLIC_API_URL);
    return response.data;
  };

export const getBagById = async (id: string): Promise<Bag> => {
  const response = await axios.get(`${PUBLIC_API_URL}bag/${id}`);
  return response.data;
};

export const deleteBag = async (id: string): Promise<void> => {
  await HttpInterceptor.delete(`${API_URL}/${id}`);
};

export const updateBag = async (id: string, updatedBag: Partial<Bag>): Promise<Bag> => {
  const response = await HttpInterceptor.put(`${API_URL}/${id}`, updatedBag);
  return response.data;
};


export const getCitySuggestions = async (): Promise<City[]> => {
  const response = await axios.get(`${PUBLIC_API_URL}cities`);
  return response.data;
};

export const updateBagRequestStatus = async (bagContentRequestId: string, status: string): Promise<boolean> => {



  try {
    const response = await HttpInterceptor.post(`${API_URL}/bagcontent/status/${bagContentRequestId}/${status}`, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    console.log("changed status succesfully");

    return true;
  } catch (error) {
    console.error('Error:', error);

    return false;
   
  }



 
};
