import React, { useState } from 'react';
import '../css/signup.css'; // Import CSS file for styling

const SignUpPage: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const [errors, setErrors] = useState<{ [key: string]: string }>({}); // Error state

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return regex.test(email);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};

    // Validation
    if (!firstName) newErrors.firstName = "First name is required.";
    if (!lastName) newErrors.lastName = "Last name is required.";
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!password) newErrors.password = "Password is required.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set errors
      return;
    }

    console.log("First Name:", firstName);
    console.log("Last Name:", lastName);
    console.log("Email:", email);
    console.log("Password:", password);
    // Add your sign-up logic here

    // Clear the form and errors
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setErrors({});
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h1 className="signup-title">Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                if (e.target.value) {
                  setErrors((prev) => ({ ...prev, firstName: '' }));
                }
              }}
              
            />
            {errors.firstName && <span className="error-message">{errors.firstName}</span>}
          </div>

          <div className="input-group">
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                if (e.target.value) {
                  setErrors((prev) => ({ ...prev, lastName: '' }));
                }
              }}
              
            />
            {errors.lastName && <span className="error-message">{errors.lastName}</span>}
          </div>

          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
               
              }}
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (e.target.value) {
                  setErrors((prev) => ({ ...prev, password: '' }));
                }
              }}
              
            />
            {errors.password && <span className="error-message">{errors.password}</span>}
          </div>

          <button type="submit" className="signup-button">Sign Up</button>
        </form>
        <div className="login-link">
          <span>Already have an account? </span>
          <a href="/login">Log in</a>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
