import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BagContent } from '../domain/BagContent';
import { getBagsContents } from '../services/bagservice';
import { Link } from 'react-router-dom';
import { ACCEPTED_STATUS, REJECTED_STATUS } from '../domain/State';

interface RequestStatsProps {
  currentBagId: string;
}



const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
  padding: 10px 0; /* Vertical padding */
`;

const StatItem = styled.div`
  text-align: center; /* Center text */
`;

const StatValue = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0; /* No margin */
`;

const StatLabel = styled.p`
  font-size: 14px;
  color: #777; /* Grey color for labels */
  margin: 0; /* No margin */
  line-height: 1.2; /* Adjust line height for better spacing */
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #666;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: red;
`;

const ViewRequestsButton = styled(Link)`
  display: block; /* Make it a block element */
  width: 100%; /* Full width */
  margin-top: 16px; /* Space above the button */
  padding: 10px 15px;
  background-color: #2767e7; /* Button color */
  color: white;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0245e; /* Darker shade on hover */
  }
`;

const BagRequestStats: React.FC<RequestStatsProps> = ({ currentBagId }) => {
  const [requests, setRequests] = useState<BagContent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const[showdetail,setShowDetail] = useState<boolean>(false);
  const[totalrequests,setTotalRequests] = useState<number>(0);
  const[totalaccepted,setTotalAccepted] = useState<number>(0);
  const[totalrejected,setTotalRejected] = useState<number>(0);



  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const requestsData = await getBagsContents(currentBagId);
      
        if ( requestsData != null && requestsData.length > 0) {
          setShowDetail(true);
          setRequests(requestsData);
        
       

        }else {
          setShowDetail(false);
        }
      } catch (error) {
        setError('Failed to load requests');
        setShowDetail(false);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [currentBagId]);

  useEffect( () => {
if (requests) {
    setTotalRequests(requests?.length);
    setTotalAccepted(requests?.filter(request => request.status === ACCEPTED_STATUS).length);
    setTotalRejected(requests?.filter(request => request.status === REJECTED_STATUS).length);
}

  } , [requests])

  if (loading) return <LoadingMessage>Loading...</LoadingMessage>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  // Calculate the total, accepted, and rejected requests

 
 
  return (
    <>
    {showdetail ?
    (
      <>
        <StatsContainer>
          <StatItem>
            <StatValue>{totalrequests}</StatValue>
            <StatLabel>Person<br />Interested</StatLabel> {/* Two-line label */}
          </StatItem>
          <StatItem>
            <StatValue>{totalaccepted}</StatValue>
            <StatLabel>Accepted</StatLabel>
          </StatItem>
          <StatItem>
            <StatValue>{totalrejected}</StatValue>
            <StatLabel>Rejected</StatLabel>
          </StatItem>
        </StatsContainer>
        <ViewRequestsButton to={`/bagcontents/${currentBagId}`}>View Requests</ViewRequestsButton>
      </>

    ) :    null
}
  </>
  );
};

export default BagRequestStats;
